import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { HomeState, HomeStore } from "./home.store";
import { Observable } from "rxjs";

@Injectable()
export class HomeQuery extends Query<HomeState> {
    constructor(protected store: HomeStore) {
        super(store);
    }

    public selectSubmitted(): Observable<boolean> {
        return this.select(s => s.submitted);
    }
}
