import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
    faArrowDown as falArrowDown,
    faBars as falBars,
    faCalendarAlt as falCalendarAlt,
    faClock as falClock,
    faDesktop as falDesktop,
    faEllipsisH as falEllipsisH,
    faEnvelope as falEnvelope,
    faMobile as falMobile,
    faTimes as falTimes,
    faVideo as falVideo,
} from "@fortawesome/pro-light-svg-icons";
import { faCaretRight as fasCaretRight, faChevronUp as fasChevronUp, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookF, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
    faBrowser as fadBrowser,
    faChartLine as fadChartLine,
    faCheckCircle as fadCheckCircle,
    faClouds as fadClouds,
    faMobileAlt as fadMobileAlt,
    faRoute as fadRoute,
    faUserCheck as fadUserCheck,
    faUserLock as fadUserLock,
    faUsers as fadUsers,
    faVials as fadVials,
    faMicroscope as fadMicroscope,
    faRadar as fadRadar,
    faComments as fadComments,
    faViruses as fadViruses,
    faShieldVirus as fadShieldVirus,
    faHospital as fadHospital,
    faSchool as fadSchool,
    faShareAlt as fadShareAlt,
    faSearch as fadSearch,
    faHeadSideMask as fadHeadSideMask
} from "@fortawesome/pro-duotone-svg-icons";

export function loadIcons(library: FaIconLibrary) {
    library.addIcons(
        falBars,
        falTimes,
        falDesktop,
        falMobile,
        falCalendarAlt,
        falEllipsisH,
        falArrowDown,
        falVideo,
        falEnvelope,
        falClock,
        fasCaretRight,
        fasChevronUp,
        faEnvelope,
        faGithub,
        faLinkedin,
        faFacebook,
        faFacebookF,

        fadCheckCircle,
        fadRoute,
        fadUserCheck,
        fadChartLine,
        fadUserLock,
        fadBrowser,
        fadMobileAlt,
        fadClouds,
        fadUsers,
        fadVials,
        fadMicroscope,
        fadRadar,
        fadComments,
        fadViruses,
        fadShieldVirus,
        fadHospital,
        fadSchool,
        fadShareAlt,
        fadSearch,
        fadHeadSideMask
    );
}
