// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appUrl: "http://localhost:4200",
    firebase: {
        apiKey: "AIzaSyC0Y6604CfwnifAktrGEP5SAt4Ue68gtvo",
        authDomain: "ceto-73c7d.firebaseapp.com",
        databaseURL: "https://ceto-73c7d.firebaseio.com",
        projectId: "ceto-73c7d",
        storageBucket: "ceto-73c7d.appspot.com",
        messagingSenderId: "840496312797",
        appId: "1:840496312797:web:f59d51a582fc6116e90634",
        measurementId: "G-MSWJZPV4ZZ"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
