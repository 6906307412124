<div class="footer pattern-cross-dots-md">
    <div class="footer-inner" fxLayout="column">
        <div fxFlex="1 1 0" fxLayout="row" fxLayoutGap="64px" fxLayout.lt-md="column" fxLayoutGap.lt-md="32px">
            <div fxLayout="column" fxLayout.lt-md="row" fxLayoutAlign.lt-md="space-between center"
                 fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
                <a class="logo" [routerLink]="'/'">
                    <img [src]="'assets/images/logos/logo-' + currentLang + '.svg'">
                </a>
                <div fxLayoutAlign="center center" class="my-32" fxLayoutGap="15px">
                    <a href="https://play.google.com/store/apps/details?id=ca.ceto.app" target="_blank" rel="noreferrer">
                        <img class="badge" [src]="'assets/images/badges/google-play-badge-' + currentLang + '.png'">
                    </a>
                    <a href="https://apps.apple.com/us/app/ceto/id1543477606" target="_blank" rel="noreferrer">
                        <img class="badge" [src]="'assets/images/badges/appstore-badge-' + currentLang + '.svg'">
                    </a>
                </div>
            </div>
            <div fxFlex="1 1 0%" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="32px">
                <div fxFlex="1 1 0%" fxLayout="column" fxLayoutAlign.lt-sm="center center">
                    <div>
                        <a class="navbar-item" routerLinkActive="active" [routerLink]="'/'"
                           [routerLinkActiveOptions]="{exact: true}">
                            {{ "components.navbar.home" | translate }}
                        </a>
                    </div>
                    <!--                    <div class="mt-12">-->
                    <!--                        <a class="navbar-item" routerLinkActive="active" [routerLink]="'/a-propos'">-->
                    <!--                            {{ "components.navbar.about" | translate }}-->
                    <!--                        </a>-->
                    <!--                    </div>-->
                    <div class="mt-20">
                        <a mat-flat-button color="accent" target="_blank" rel="noreferrer" href="https://calendly.com/oclair">
                            {{ "general.demo" | translate }}
                            <mat-icon>chevron_right</mat-icon>
                        </a>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign.lt-sm="center center">
                    <div class="contact">
                        <p class="footer-address">
                            203-1040 rue Notre-Dame<br>
                            Lavaltrie, QC, J5T 1M1<br>
                            Canada<br>
                        </p>
                        <p>
                            <a href="mailto:info@oclair.ca">info@oclair.ca</a>
                        </p>
                    </div>
                    <div fxLayoutGap="10px">
                        <a class="footer-social-link" href="https://facebook.com/cetoparoclairenvironnement" target="_blank"
                           rel="noreferrer">
                            <fa-icon [icon]="['fab', 'facebook']" size="2x"></fa-icon>
                        </a>
                        <a class="footer-social-link" href="https://www.linkedin.com/company/ceto-oclair-environnement/" target="_blank"
                           rel="noreferrer">
                            <fa-icon [icon]="['fab', 'linkedin']" size="2x"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright mt-16">
            <a href="https://oclair.ca">© 2021 OClair Environnement</a>
        </div>
    </div>
</div>
