<div class="container">
    <div class="content">
        <div class="nav">
            <ceto-navbar></ceto-navbar>
        </div>
        <div class="hero">
            <div class="hero-background-container">
                <div class="hero-bubbles-container">
                    <div class="hero-bubbles-grid">
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                    </div>
                </div>
                <div class="hero-background pattern-cross-dots-md"></div>
            </div>
            <div class="hero-content-container section" fxLayoutAlign="start start" fxLayout.lt-md="column-reverse">
                <div fxFlex="50" fxLayout="column" fxLayoutGap="32px" fxFlex.lt-md="100%">
                    <div class="hero-title">
                        <div class="hero-tagline headline">{{ "pages.home.hero.tagline" | translate }}</div>
                        {{ "pages.home.hero.title" | translate }}
                        <span class="emphasis">{{ "pages.home.hero.title_accent" | translate }}</span>
                    </div>
                    <div class="hero-description">
                        {{ "pages.home.hero.description" | translate }}
                    </div>
                    <div class="hero-cta" fxLayout="row" fxLayoutGap="16px" fxLayout.lt-sm="column" fxLayoutAlign="start center">
                        <a mat-flat-button color="accent" href="#presentation">
                            {{ "pages.home.presentation.receive" | translate }}
                            <mat-icon>chevron_right</mat-icon>
                        </a>
                        <a mat-flat-button color="accent" target="_blank" rel="noreferrer" href="https://calendly.com/oclair">
                            {{ "general.demo" | translate }}
                            <mat-icon>chevron_right</mat-icon>
                        </a>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%" class="hero-image">
                    <img class="hero-web-ss" [src]="'../../../assets/images/screenshots/' + currentLang + '/sampling-point-view.png'">
                    <img class="hero-mobile-ss" [src]="'../../../assets/images/screenshots/' + currentLang + '/ipad-sample.png'">
                </div>
            </div>
        </div>
        <div class="overview">
            <div class="section">
                <h1 class="text-center">
                    {{ "pages.home.overview.title" | translate }}
                    <span class="emphasis">{{ "pages.home.overview.title_accent" | translate }}</span>
                </h1>
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px">
                    <div class="overview-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px"
                         fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-blue" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'vials']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h2>{{ "pages.home.overview.samples.title" | translate }}</h2>
                            <p>{{ "pages.home.overview.samples.description" | translate }}</p>
                        </div>
                    </div>
                    <fa-icon [icon]="['fal', 'arrow-down']"></fa-icon>
                    <div class="overview-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px"
                         fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-green" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'microscope']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h2>{{ "pages.home.overview.analysis.title" | translate }}</h2>
                            <p>{{ "pages.home.overview.analysis.description" | translate }}</p>
                        </div>
                    </div>
                    <fa-icon [icon]="['fal', 'arrow-down']"></fa-icon>
                    <div class="overview-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px"
                         fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-red" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'viruses']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h2>{{ "pages.home.overview.detection.title" | translate }}</h2>
                            <p>{{ "pages.home.overview.detection.description" | translate }}</p>
                        </div>
                    </div>
                    <fa-icon [icon]="['fal', 'arrow-down']"></fa-icon>
                    <div class="overview-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px"
                         fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-blue" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'comments']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h2>{{ "pages.home.overview.share.title" | translate }}</h2>
                            <p>{{ "pages.home.overview.share.description" | translate }}</p>
                        </div>
                    </div>
                    <fa-icon [icon]="['fal', 'arrow-down']"></fa-icon>
                    <div class="overview-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px"
                         fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-green" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'shield-virus']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h2>{{ "pages.home.overview.decision.title" | translate }}</h2>
                            <p>{{ "pages.home.overview.decision.description" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container analysis">
            <div class="section-background-container">
                <div class="section-background analysis-background pattern-cross-dots-lg"></div>
            </div>
            <div class="section">
                <div fxLayoutAlign="start center" fxLayout.lt-md="column-reverse">
                    <div fxFlex="50">
                        <h1>{{ "pages.home.analysis.title" | translate }}</h1>
                        <ul>
                            <li>
                                {{ "pages.home.analysis.statement_1" | translate }}
                                <strong>{{ "pages.home.analysis.statement_1_other" | translate }}</strong>
                            </li>
                            <li>
                                {{ "pages.home.analysis.statement_2" | translate }}
                                <strong>{{ "pages.home.analysis.statement_2_other" | translate }}</strong>
                            </li>
                            <li>
                                <strong>{{ "pages.home.analysis.statement_3" | translate }}</strong>
                                {{ "pages.home.analysis.statement_3_other" | translate }}
                            </li>
                        </ul>

                        <div class="mt-32">
                            <a mat-flat-button color="white" target="_blank" rel="noreferrer" href="https://calendly.com/oclair">
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div fxFlex="50" class="analysis-image">
                        <img class="analysis-ss" [src]="'../../../assets/images/screenshots/' + currentLang + '/sample-analysis.png'">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container localization">
            <div class="section-background-container">
                <div class="section-background localization-background pattern-cross-dots-lg"></div>
            </div>
            <div class="section">
                <div fxLayoutAlign="start center" fxLayoutGap="64px" fxLayout.lt-md="column">
                    <div fxFlex="50" class="localization-image">
                        <img src="assets/images/undraw_map_1r69.svg">
                    </div>
                    <div fxFlex="50">
                        <h1>{{ "pages.home.localization.title" | translate }}</h1>
                        <p>{{ "pages.home.localization.description" | translate }}</p>

                        <div class="mt-32">
                            <a mat-flat-button color="accent" target="_blank" rel="noreferrer" href="https://calendly.com/oclair">
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container shared-information">
            <div class="section-background-container">
                <div class="section-background shared-information-background pattern-cross-dots-lg"></div>
            </div>
            <div class="section">
                <div fxLayoutAlign="start center" fxLayoutGap="64px" fxLayout.lt-md="column">

                    <div fxFlex="50" class="shared-information-image">
                        <img class="shared-information-ss"
                             [src]="'../../../assets/images/screenshots/' + currentLang + '/dashboard-map.png'">
                    </div>
                    <div fxFlex="50">
                        <h1>{{ "pages.home.share.title" | translate }}</h1>
                        <ul>
                            <li>
                                {{ "pages.home.share.statement_1" | translate }}
                                <strong>{{ "pages.home.share.statement_1_other" | translate }}</strong>
                            </li>
                            <li>
                                {{ "pages.home.share.statement_2" | translate }}
                                <strong>{{ "pages.home.share.statement_2_other" | translate }}</strong>
                            </li>
                            <li>
                                {{ "pages.home.share.statement_3" | translate }}
                                <strong>{{ "pages.home.share.statement_3_other" | translate }}</strong>
                            </li>
                        </ul>

                        <div class="mt-32">
                            <a mat-flat-button color="accent" target="_blank" rel="noreferrer" href="https://calendly.com/oclair">
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container communication">
            <div class="section">
                <h1 class="text-center">
                    {{ "pages.home.communication.title" | translate }}
                    <span class="emphasis">{{ "pages.home.communication.title_accent" | translate }}</span>
                </h1>

                <div class="mt-64" fxLayoutAlign="start center" fxLayoutGap="64px" fxLayout.lt-md="column">
                    <div class="communication-image">
                        <img class="shared-information-ss" src="assets/images/undraw_report_mx0a.svg">
                    </div>
                    <div fxFlex="1 1 0%">
                        <h2>{{ "pages.home.communication.statement_1_title" | translate }}</h2>
                        <p>{{ "pages.home.communication.statement_1_description" | translate }}</p>
                        <p>{{ "pages.home.communication.statement_1_description_other" | translate }}</p>
                    </div>
                </div>
                <div class="mt-64" fxLayoutAlign="start center" fxLayoutGap="64px" fxLayout.lt-md="column-reverse">
                    <div fxFlex="1 1 0%">
                        <h2>{{ "pages.home.communication.statement_2_title" | translate }}</h2>
                        <p>
                            {{ "pages.home.communication.statement_2_description" | translate }}
                            <strong>{{ "pages.home.communication.statement_2_description_bold" | translate }}</strong>
                            {{ "pages.home.communication.statement_2_description_other" | translate }}
                        </p>
                        <div class="mt-32" fxLayoutAlign="start center" fxLayoutGap="32px" fxLayoutAlign.lt-md="center center">
                            <fa-icon class="light-green-fg" [icon]="['fad', 'hospital']" size="3x"></fa-icon>
                            <fa-icon class="light-green-fg" [icon]="['fad', 'school']" size="3x"></fa-icon>
                            <fa-icon class="light-green-fg" [icon]="['fad', 'share-alt']" size="3x"></fa-icon>
                        </div>
                    </div>
                    <div class="communication-image">
                        <img class="shared-information-ss" src="assets/images/undraw_medical_care_movn.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container protect">
            <div class="section-background-container">
                <div class="section-background protect-background"></div>
            </div>
            <div class="section">
                <h1 class="text-center">
                    {{ "pages.home.protect.title" | translate }}
                    <span class="emphasis">{{ "pages.home.protect.title_accent" | translate }}</span>
                </h1>
                <div class="mt-64" fxLayoutAlign="start start" fxLayout.lt-md="column" fxLayoutGap="32px"
                     fxLayoutAlign.lt-md="start center">
                    <div class="p-item" fxFlex="1/3" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px">
                        <div fxFlex="150px" class="bubble-icon bi-green" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'search']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h1>{{ "pages.home.protect.predict.title" | translate }}</h1>
                            <p>{{ "pages.home.protect.predict.description" | translate }}</p>
                        </div>
                    </div>
                    <div class="p-item" fxFlex="1/3" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px">
                        <div fxFlex="150px" class="bubble-icon bi-red" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'viruses']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h1>{{ "pages.home.protect.prevent.title" | translate }}</h1>
                            <p>{{ "pages.home.protect.prevent.description" | translate }}</p>
                        </div>
                    </div>
                    <div class="p-item" fxFlex="1/3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
                        <div fxFlex="150px" class="bubble-icon bi-blue" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'head-side-mask']" size="3x"></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h1>{{ "pages.home.protect.protect.title" | translate }}</h1>
                            <p>{{ "pages.home.protect.protect.description" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="presentation" class="section-container presentation" [niceLoadingOverlay]="loading$ | async">
            <div class="section">
                <h1 class="text-center">
                    {{ "pages.home.presentation.title" | translate }}
                    <span class="emphasis">{{ "pages.home.presentation.title_accent" | translate }}</span>
                </h1>
                <div class="text-center">{{ "pages.home.presentation.subtitle" | translate }}</div>
                <div class="my-32" [ngSwitch]="submitted$ | async">
                    <form *ngSwitchCase="false" [formGroup]="formGroup" novalidate fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="16px"
                          fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center" fxLayoutGap.lt-md="0px">
                        <mat-form-field class="email-input" fxFlex="1 1 0" appearance="none" floatLabel="never" niceControlStatus>
                            <mat-label>{{ 'pages.home.presentation.email' | translate }}</mat-label>
                            <input matInput type="email" name="email" formControlName="email"/>
                        </mat-form-field>

                        <button mat-flat-button color="accent" (click)="clickDownloadPresentation()">
                            {{ "pages.home.presentation.receive" | translate }}&nbsp;
                            <mat-icon>email</mat-icon>
                        </button>
                    </form>

                    <div *ngSwitchCase="true" fxLayout="column" fxLayoutAlign="center center">
                        <fa-duotone-icon [icon]="['fad', 'check-circle']" size="8x" [classes]="['emphasis']"></fa-duotone-icon>
                        <h2 class="text-center">{{ "pages.home.presentation.submitted_title" | translate }}</h2>
                        <p class="text-center mb-0">{{ "pages.home.presentation.submitted_subtitle" | translate }}</p>
                        <p class="text-center mt-0">{{ "pages.home.presentation.submitted_demo" | translate }}</p>
                        <a mat-flat-button color="accent" target="_blank" rel="noreferrer" href="https://calendly.com/oclair">
                            {{ "general.demo" | translate }}
                            <mat-icon>chevron_right</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <ceto-footer></ceto-footer>
    </div>
</div>
