import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { HomeModule } from "./pages/home/home.module";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent
    },
    {
        path: "**",
        redirectTo: ""
    }
];

@NgModule({
    imports: [
        HomeModule,
        RouterModule.forRoot(routes, {
            useHash: false, scrollPositionRestoration: "enabled", initialNavigation: "enabled"
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
