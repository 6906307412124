import { InjectionToken, Provider } from "@angular/core";

export const LOCAL_STORAGE_TOKEN = new InjectionToken<Storage>("localStorage");

export const LOCAL_STORAGE_PROVIDER: Provider = {
    provide: LOCAL_STORAGE_TOKEN,
    useFactory: localStorageFactory
};

export class MockLocalStorage implements Storage {
    readonly length: number = 0;
    clear(): void {}
    getItem(key: string): string | null { return null; }
    key(index: number): string | null { return null; }
    removeItem(key: string): void {}
    setItem(key: string, value: string): void {}
    [name: string]: any;
}

export function localStorageFactory(): Storage {
    if (typeof window !== "undefined") {
        return window.localStorage;
    }

    return new MockLocalStorage();
}

