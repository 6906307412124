import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "ceto-footer",
    templateUrl: "footer.template.html",
    styleUrls: ["./footer.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
    public get currentLang(): string {
        return this.translateService.currentLang;
    }

    constructor(
        private translateService: TranslateService
    ) { }

    public ngOnInit(): void { }
}
