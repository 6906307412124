import { Store, StoreConfig } from "@datorama/akita";

export interface HomeState {
    submitted: boolean;
}

export const initialState: HomeState = {
    submitted: false
};

@StoreConfig({
    name: "home",
    resettable: true
})
export class HomeStore extends Store<HomeState> {
    constructor() {
        super(initialState);
    }
}
