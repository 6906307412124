import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HomeStore } from "./home.store";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class HomeService {
    constructor(
        private http: HttpClient,
        private store: HomeStore,
        private translateService: TranslateService
    ) { }

    public async submitEmailForDownload(email: string): Promise<void> {
        try {
            this.store.setLoading(true);
            await this.http.post<void>("https://us-central1-ceto-73c7d.cloudfunctions.net/sendPresentation", {
                email,
                lang: this.translateService.currentLang
            }).toPromise();
            this.store.update({
                submitted: true
            });
        } catch (e) {
            this.store.setError(e);
        } finally {
            this.store.setLoading(false);
        }
    }
}
