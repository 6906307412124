import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { environment } from "../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { LOCAL_STORAGE_PROVIDER, LOCAL_STORAGE_TOKEN } from "./providers/local-storage.provider";
import { HttpClientModule } from "@angular/common/http";
import { loadIcons } from "./app.icons";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { NgsRevealModule } from "ngx-scrollreveal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule, ScreenTrackingService } from "@angular/fire/analytics";

registerLocaleData(localeFr);
registerLocaleData(localeEn);

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const localStorage = injector.get<Storage>(LOCAL_STORAGE_TOKEN);
            let lang = (localStorage.getItem("lang") || translate.getBrowserLang()) ?? "en";
            if (!["fr", "en"].includes(lang)) {
                lang = "en";
            }
            translate.setDefaultLang(lang);
            translate.use(lang).toPromise().then(() => resolve());
        });
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        HttpClientModule,
        AppRoutingModule,
        NgsRevealModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        AkitaNgRouterStoreModule
    ],
    providers: [
        LOCAL_STORAGE_PROVIDER,
        ScreenTrackingService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        loadIcons(library);
    }
}
