import { NgModule } from "@angular/core";
import { NavbarComponent } from "./navbar.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    imports: [
        FlexLayoutModule,
        RouterModule,
        CommonModule,
        TranslateModule,
        FontAwesomeModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [NavbarComponent],
    declarations: [NavbarComponent],
    providers: [],
})
export class NavbarModule {
}
