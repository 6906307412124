import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, ngxFormGeneratorFactory } from "@recursyve/ngx-form-generator";
import { DownloadEmailForm } from "./download-email.form";
import { HomeQuery } from "./store/home.query";
import { HomeService } from "./store/home.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "ceto-home",
    templateUrl: "home.template.html",
    styleUrls: ["./home.style.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: GeneratedFormGroup,
            useFactory: ngxFormGeneratorFactory(DownloadEmailForm)
        }
    ]
})
export class HomeComponent implements OnInit {
    public loading$ = this.query.selectLoading();
    public submitted$ = this.query.selectSubmitted();

    constructor(
        public formGroup: GeneratedFormGroup<DownloadEmailForm>,
        private query: HomeQuery,
        private service: HomeService,
        private translateService: TranslateService
    ) { }

    public ngOnInit(): void { }

    public clickDownloadPresentation() {
        if (!this.validate()) {
            return;
        }
        this.service.submitEmailForDownload(this.formGroup.getRawValue()?.email);
    }

    private validate(): boolean {
        if (this.formGroup.valid) {
            return true;
        }

        this.formGroup.markAllAsTouched();
        return false;
    }

    public get currentLang(): string {
        return this.translateService.currentLang;
    }
}
