import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class NavigationService {
    public onChange$: Subject<string> = new Subject<string>();

    public navigate(element: string) {
        this.onChange$.next(element);
    }
}
