import { NgModule } from "@angular/core";
import { HomeComponent } from "./home.component";
import { NavbarModule } from "../../components/navbar/navbar.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgsRevealModule } from "ngx-scrollreveal";
import { FooterModule } from "../../components/footer/footer.module";
import { NiceFormErrorModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { HomeStore } from "./store/home.store";
import { HomeQuery } from "./store/home.query";
import { HomeService } from "./store/home.service";

@NgModule({
    imports: [
        NavbarModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        FontAwesomeModule,
        NgsRevealModule,
        FooterModule,
        NiceLoadingSpinnerModule,
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        NiceFormErrorModule,
        TranslateModule,
        MatInputModule
    ],
    exports: [HomeComponent],
    declarations: [HomeComponent],
    providers: [
        HomeStore,
        HomeQuery,
        HomeService
    ],
})
export class HomeModule {
}
